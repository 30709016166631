<template>
  <div class="commission">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
        </template>
        <template #title>
          <div class="navbar-title">My team</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="content">
      <van-image width="100%" fill="contain" :src="require('./img/img.png')" />
      <div class="bg">
        <div class="bg-top" @click="to_withdrawal"></div>
        <div class="bg-bottom" @click="show = true"></div>
      </div>
    </div>

    <div class="modal-box">
      <van-action-sheet v-model="show">
        <div class="buy-content">
          <van-image width="100%" :src="require(`./img/img1.png`)" />
          <div class="close" @click.stop="show = false"></div>
          <div class="bg"></div>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    to_withdrawal() {
      // this.$router.push({ path: "/withdrawal" });
    },
  },
};
</script>

<style lang="scss">
.commission {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .navbar-title {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      color: #000000;
      font-weight: bold;
      position: relative;
    }
  }

  .content {
    position: relative;
    padding-bottom: 1.25rem;
    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      .bg-top {
        height: 42%;
      }

      .bg-bottom {
        height: 58%;
      }
    }
  }

  .buy-content {
    position: relative;
    .close {
      width: 5.25rem;
      height: 5.25rem;
      top: 0;
      right: 0;
      position: absolute;
      z-index: 5;
    }

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>